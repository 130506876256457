



























import Vue from "vue";
import ChangeLanguage from "@/components/shared/ChangeLanguage.vue";
import { IApp } from "@/interfaces";
import { clean_string } from "@/utils/global";

export default Vue.extend({
  name: "AuthBoardingHeader",
  components: { ChangeLanguage },
  computed: {
    AppPortal(): IApp.Portals {
      return process.env.VUE_APP_PORTAL || IApp.Portals.HCMS;
    },
    Portals() {
      return IApp.Portals;
    }
  },
  methods: {
    clean_string
  }
});
