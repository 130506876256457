
























































































import { IApp } from "@/interfaces";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import { SiteDirections } from "@/store/modules/common/interfaces";
import { clean_string } from "@/utils/global";
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "OnboardingDecoration",
  data() {
    return {
      dash_images: [
        require("@/assets/images/onboarding_decoration/dashboard2.png"),
        require("@/assets/images/onboarding_decoration/dashboard1.png"),
        require("@/assets/images/onboarding_decoration/dashboard3.png")
      ] as string[],
      active_img: require("@/assets/images/onboarding_decoration/onboardAi.png"),
      index: 1
    };
  },
  // created() {
  //   if (clean_string(this.AppPortal) === clean_string(this.Portals.COREHCS)) {
  //     this.dash_images = [
  //       require("@/assets/images/onboarding_decoration/corehcs_dashboard1.png"),
  //       require("@/assets/images/onboarding_decoration/corehcs_dashboard3.png"),
  //       require("@/assets/images/onboarding_decoration/corehcs_dashboard3.png")
  //     ] as string[];
  //   } else if (
  //     clean_string(this.AppPortal) === clean_string(this.Portals.COREHCMS)
  //   ) {
  //     this.dash_images = [
  //       require("@/assets/images/onboarding_decoration/corehcms_dashboard1.png"),
  //       require("@/assets/images/onboarding_decoration/corehcms_dashboard3.png"),
  //       require("@/assets/images/onboarding_decoration/corehcms_dashboard3.png")
  //     ] as string[];
  //   }
  // },
  mounted() {
    // this.active_img = this.dash_images[this.index];
  },
  computed: {
    SiteDirections() {
      return SiteDirections;
    },
    AppPortal(): IApp.Portals {
      return process.env.VUE_APP_PORTAL;
    },
    Portals() {
      return IApp.Portals;
    },
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    })
  },
  methods: {
    clean_string,
    /**
     * Change decoration picture on left/right icon click
     * @param direction{number}  => 0: if left icon btn pressed
     *                             1: if right icon btn pressed
     */
    change_picture(direction: number) {
      const decoration_image = this.$refs.img as HTMLImageElement;
      // Add transition effect class
      decoration_image.classList.add("transitioning-opacity");
      if (direction) {
        if (this.index === this.dash_images.length - 1) this.index = 0;
        else this.index += 1;
      } else {
        if (this.index === 0) this.index = this.dash_images.length - 1;
        else this.index -= 1;
      }
      this.active_img = this.dash_images[this.index];
      // Remove transition effect class
      setTimeout(
        () => decoration_image.classList.remove("transitioning-opacity"),
        500
      );
    },
    /**
     * Change image on dot icons click
     * @param index Index of dot pressed
     */
    update_dot(index: number) {
      const decoration_image = this.$refs.img as HTMLImageElement;
      // Add transition effect class
      decoration_image.classList.add("transitioning-opacity");
      this.index = index;
      this.active_img = this.dash_images[this.index];
      // Remove transition effect class
      setTimeout(
        () => decoration_image.classList.remove("transitioning-opacity"),
        500
      );
    }
  }
});
